import styled from 'styled-components'

export const PullDownToRefresh = styled.div.attrs((props) => ({}))`
    text-align: center;
    font-size: 12px;
    color: #fff;
`

export const ReleaseToRefreshStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
    font-size: 12px;
    color: #fff;
`

export const EndMessageStyled = styled.div.attrs((props) => ({}))`
    text-align: center;
    font-size: 12px;
    color: #fff;
`
export const NoContentStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    padding-top: 5px;
    color: rgba(157, 157, 157, 1);
    padding-left: 10px;
    font-family: Inter, sans-serif;
    font-weight: 600;
`
