export enum SearchResultType {
    INTERNAL = 'INTERNAL',
    IFRAME = 'IFRAME',
    BESPOKE_DIALOG = 'BESPOKE_DIALOG',
    DEAL = 'DEAL',
}

export interface SearchFieldsDTO {
    label: string

    displayOrder: number

    /**
     * can be undefined, as in 'Search All'
     * or
     * 'tickers' a valid column
     */
    field?: string

    /**
     * if this field is selected then ensure not possible to select 'disableFields'
     */
    disableFields?: string[]

    /**
     * server side setting i.e. initial selected value
     */
    selected: boolean

    /**
     * UI only, grayed out if not selectable
     */
    selectable?: boolean
}

export interface GlobalSearchDTO {
    selectUrl: string
    fields?: SearchFieldsDTO[]
    autoSearchLength?: number | undefined
}

export interface GlobalSearchResponseDO {
    title: string

    /**
     * short synopsis of teh result
     */
    summary: string

    content?: string

    icon?: string

    closed?: boolean

    label?: string
    dealId?: string

    resultType?: string

    /**
     * dictates to UI the action to perform on click i.e. go to specific path in app or open an Iframe modal
     */
    type: SearchResultType

    /**
     *  used in conjuction with 'type', either path is a link to a path within FE e.g. /article/1 or a src for an Iframe
     */
    path: string

    /**
     * relevant date for returned date. not displayed if null
     */
    date: string

    /**
     * header bar background color
     */
    headerBgColor?: string

    /**
     * header bar color
     */
    headerColor?: string
}
