import React from 'react'
import { HOLDCO, PRE_EVENT, REGULATION, RIGHTS_ISSUE, RISK_ARB, RUMOURS, SHARE_CLASS, SPIN_OFFS } from '../navigation/SecondaryOttoNav'
import { OSSArticleDTO } from '../types'
import { ArticleTitleDealStyled } from './styled'

export type DealTypeProperties = {
    article?: OSSArticleDTO
    dealType?: string
}
const DealTypeLabel = ({ article, dealType }: DealTypeProperties): React.ReactElement => {
    let dealLabel = dealType || ''
    if (article && article.dealType) {
        switch (article.dealType) {
            case 'HOLD_CO':
                dealLabel = HOLDCO
                break
            case 'RISK_ARB':
                dealLabel = RISK_ARB
                break
            case 'SHARE_CLASS':
                dealLabel = SHARE_CLASS
                break
            case 'PRE_EVENT':
                dealLabel = PRE_EVENT
                break
            case 'GENERAL':
                // dealLabel = (article.title || '').toUpperCase() // GENERAL
                dealLabel = 'GENERAL'
                break
            case 'RECOMMENDATIONS':
                dealLabel = (article.dealName || '').toUpperCase() // GENERAL
                break
            case 'SPIN_OFFS':
                dealLabel = SPIN_OFFS
                break
            case 'RIGHTS_ISSUE':
                dealLabel = RIGHTS_ISSUE
                break
            case 'RUMOURS':
                dealLabel = RUMOURS
                break
            case 'REGULATION':
                dealLabel = REGULATION
                break
            default:
                console.log('article.dealType not found', article.dealType)
        }
        return <ArticleTitleDealStyled>{dealLabel}</ArticleTitleDealStyled>
    } else {
        return <ArticleTitleDealStyled />
    }
}

export default DealTypeLabel
