import styled, { ThemeProps } from 'styled-components'
import { isDefined } from '../../../utils/TypeCheckers'
import { DecimalDO } from '../../types'
import { ExtraFieldProperties } from '../money/types'

interface DecimalStyledProps extends ThemeProps<any> {
    decimal: DecimalDO | undefined
    extraProperties: ExtraFieldProperties
    inline: boolean
    textAlign?: boolean
    storagePrefix?: string | undefined
}

export const marginRight = `
    @media (min-width: 400px) {
        margin-right: 10px;
    }
    @media (min-width: 992px) {
        margin-right: 20px;
    }
    @media (min-width: 1500px) {
        margin-right: 30px;
    }
    @media (min-width: 2000px) {
        margin-right: 40px;
    }
    @media (min-width: 2500px) {
        margin-right: 50px;
    }
    @media (min-width: 3000px) {
    text-align:center;
    }
`

export const DecimalStyled = styled.div.attrs((props: DecimalStyledProps) => ({}))<DecimalStyledProps>`
    ${(props) => {
        if (props.textAlign && props.storagePrefix && (props.storagePrefix + '').startsWith('olive_fe')) {
            return marginRight
        } else {
            return false
        }
    }};

    color: ${(props) => {
        // let value = props.theme.table.columnColor
        let value = 'inherit'
        if (isDefined(props?.extraProperties)) {
            try {
                // @ts-ignore
                const properties = JSON.parse(props?.extraProperties)
                if (properties.colorise) {
                    if (props.decimal && props.decimal.v) {
                        value = props.decimal.v < 0 ? props.theme.money.negativeColor : props.theme.money.positiveColor
                    }
                }
                /* eslint-disable @typescript-eslint/no-unused-vars */
            } catch (err) {
                // console.log('error extract extra properties', err)
            }
        }
        return value
    }};
    // color: ${(props) => props.theme.table.columnColor} !important;
    // font-size: ${(props) => props.theme.table.columnFontSize};

    ${(props) => props.inline && 'display:inline;'}
`
