import styled, { ThemeProps } from 'styled-components'
import { getTextAlign } from '../../../utils/CssUtils'
import { FormMode } from '../../types'
import { marginRight } from '../decimal/styled'
import { defaultCellWidth } from '../gusl-table/guslTableSlice'
import { ColumnFieldProperties } from '../maintain-table/styled'

export const TextFieldStyled = styled.div.attrs((props: ColumnFieldProperties) => ({}))<ColumnFieldProperties>`
    text-align: ${(props) => getTextAlign(props.textAlign)};

    ${(props) => {
        if ('right' === getTextAlign(props.textAlign) && props.storagePrefix && (props.storagePrefix + '').startsWith('olive_fe')) {
            return marginRight
        } else {
            return false
        }
    }};

    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    // end of ellipsis
    overflow-wrap: break-word; /* This allows words to break and wrap onto the next line */
    word-wrap: break-word;

    ${(props) => props.hasLink && ':hover {cursor:pointer;text-decoration: underline;}'}
    strong {
        font-weight: bold;
        // GBW 7-Aug clashes with article
        // color: ${(props) => props.theme.colors.warning} !important;
    }

    //border:3px solid greenyellow;
`

interface InputStyledProps extends ThemeProps<any> {
    noValue: boolean
    submitted: boolean
    inLine?: boolean
}

/*
    className: "form-control form-control-sm bg-transparent rounded-0 text-light  " +
        ((props.submitted && props.noValue) ? 'yellow' : '')

 */
export const InputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: 'form-control form-control-sm bg-transparent rounded-0 text-light  ' + (props.submitted && props.noValue ? 'yellow' : ''),
}))<InputStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};

    ${(props) => props.inLine && 'border: solid 1px #434343 !important;'}
    ${(props) => props.inLine && 'border-radius: 5px !important;'}
    ${(props) => props.inLine && 'padding-left:5px !important;'}
    ${(props) => props.inLine && 'line-height: 16px;'}
    ${(props) => props.inLine && 'padding-bottom: 12px;'}
    ${(props) => props.inLine && 'padding-top: 8px;  '}
    .form-control {
        border-bottom: none !important;
    }
`
/*
    //border: solid 1px #434343 !important;
  border-bottom: ${props => props.theme.form.fieldBorder} !important;
 */

export const InlineInputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: 'bg-transparent rounded-0 text-light  ' + (props.submitted && props.noValue ? 'yellow' : ''),
}))<InputStyledProps>`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: ${(props) => props.theme.table.columnFontSize};
    border: none;
    border-bottom: ${(props) => props.theme.form.fieldBorder};

    /* Hide Spin arrows on input type number */

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;

        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;

        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
`

export const FloatingFormStyled = styled.div.attrs((props) => ({
    className: 'form-floating ffs',
}))``

export const FloatingFormTextFilterStyled = styled.div.attrs((props) => ({}))``

interface FieldContentWrapperProperties {
    inLine?: boolean
    isMobile?: boolean
    isEdit?: boolean
    isLookup?: boolean
    startPos?: number
    windowWidth?: number
    fieldType?: string
}

export const FieldContentWrapperStyled = styled.div.attrs((props: FieldContentWrapperProperties) => ({
    className: props.inLine ? '' : 'form-control bg-transparent rounded-0 ' + (props.fieldType === 'wysiwyg' ? '' : 'text-light '),
}))<FieldContentWrapperProperties>`
    ${(props) =>
        props.isLookup &&
        props.windowWidth &&
        props.startPos &&
        props.isMobile &&
        'width: ' +
            (props.windowWidth - props.startPos > 300
                ? '300px  !important;'
                : props.windowWidth - props.startPos - 10 + 'px  !important;')};
`

/*
  ${props => !props.inLine && props.isEdit && 'border-bottom: ' + props.theme.form.fieldBorder + ' !important;'}

 */

interface FieldContentStyledProperties {
    formMode?: FormMode
}
/*
    // input[type='text'],
    // input[type='number'],
    // input[type='lookup'] {
    //     ${(props) =>
    //         props.formMode &&
    //         (props.formMode === FormMode.EDIT || props.formMode === FormMode.NEW) &&
    //         'border: 1px solid ' + props.theme.colors.accentColorMuted + ' !important; '}
    // }

 */
export const FieldContentStyled = styled.div.attrs((props: FieldContentStyledProperties) => ({}))<FieldContentStyledProperties>`
    ${(props) => {
        if (props.formMode === FormMode.EDIT || props.formMode === FormMode.NEW) {
            return 'border: 1px solid ' + (props?.theme?.form?.entryBorderColor || props.theme.colors.accentColorMuted) + ' !important; '
        } else {
            return 'border: 1px solid ' + (props?.theme?.form?.viewBorderColor || props.theme.colors.accentColorMuted) + ' !important; '
        }
    }}

    ${(props) => 'margin: ' + (props?.theme?.form?.margin || '2px')};
    ${(props) => 'border-radius: ' + (props?.theme?.form?.borderRadius || '10px')};
    ${(props) => 'padding: ' + (props?.theme?.form?.padding || '5px')};
`

interface ErrorMessageProps extends ThemeProps<any> {
    submitted: boolean
    errorMessage: string | undefined
}

export const FieldErrorStyled = styled.div.attrs((props: ErrorMessageProps) => ({
    className: 'invalid',
}))<ErrorMessageProps>`
    display: ${(props) => (props.submitted && props.errorMessage ? 'inline' : 'none')};
    font-size: 12px;
`

export const HintStyled = styled.div.attrs((props) => ({}))`
    color: ${(props) => props.theme.table.columnColor} !important;
    font-size: 12px;
`
