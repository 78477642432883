import styled, { ThemeProps } from 'styled-components'
import { IconProperties } from '../table-controls/styled'

interface SearchDropdownItemProperties extends ThemeProps<any> {
    className?: string
    key?: number
    role?: string
    onClick?: Function
    active?: boolean
}

export interface SearchIconProperties extends ThemeProps<any> {
    // icon: string
    onClick: Function
    className: string
    active: boolean
    role: string
    id: string
    haveNoAuto?: boolean
}

/*
      background-color: ${(props) => (props.active ? props.theme.colors.dark[0] : props.theme.colors.light)};

 */
export const SearchDropdownItemStyled = styled.div.attrs((props: SearchDropdownItemProperties) => ({}))<SearchDropdownItemProperties>`
      color: ${(props) => (!props.active ? props.theme.colors.dark[0] : props.theme.colors.dark[1])};
      background-color: ${(props) =>
          props.active ? props.theme?.filterControls?.bgColor || props.theme.table.panelBgColor : props.theme.colors.light};
      border-bottom: 1px solid ${(props) => (props.active ? props.theme.colors.dark[1] : props.theme.colors.dark[0])};
    
      :hover {
        background-color: ${(props) => props.theme.colors.dark[1]};
        color: ${(props) => props.theme.colors.dark[0]};


    `
export const FuzzySearchStyled = styled.div.attrs((props: SearchDropdownItemProperties) => ({}))<SearchDropdownItemProperties>`
     color:${(props) => (props.active ? props.theme.colors.dark[0] : props.theme.colors.accentColor)};
     background-color: ${(props) =>
         props.active ? props.theme?.filterControls?.bgColor || props.theme.table.panelBgColor : props.theme.colors.light};
    
     border-bottom:1px solid ${(props) => (props.active ? props.theme.colors.background[1] : props.theme.colors.background[0])};
     :hover{
     background-color:${(props) => props.theme.colors.background[0]};
     color:${(props) => props.theme.colors.accentColor};
    `

export const SearchIconStyled = styled.span.attrs((props: SearchIconProperties) => ({
    className: props.className,
    id: props.id,
    onClick: props.onClick,
    role: props.role,
}))<SearchIconProperties>`
    color: ${(props) => props.theme.colors.dark[1]};
    background-color: ${(props) => props.theme?.filterControls?.bgColor || props.theme.table.panelBgColor};

    margin-left: 1px !important;
    ${(props) => props.haveNoAuto && 'background-color:var(--toastify-color-warning);'}
`
export const ResetSearchStyled = styled.span.attrs((props: SearchIconProperties) => ({
    onClick: props.onClick,
    role: 'button',
}))<SearchIconProperties>`
    color: ${(props) => props.theme.colors.dark[1]};
    margin-top: 5px;
`
