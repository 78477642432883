import styled, { ThemeProps } from 'styled-components'

export interface TableStyledProps extends ThemeProps<any> {
    code?: string
    className?: string
    tBodyHeight?: number
    tBodyWidth?: number
    isOverFlown?: boolean
    id?: string
    overflown?: boolean
    width?: number | undefined
    isBeingResized?: boolean | undefined
    isMobile?: boolean
    hide?: boolean
    // MK 06/09/2023
    isSquashed?: boolean | undefined
    isNested?: boolean
    bodyStartPos?: number
    isOverFlownY?: boolean
    isSummaryHeader?: boolean | undefined
    isSummaryFooter?: boolean | undefined
    footerHeight?: number
    startPos?: number
    fixedHeight?: number
    storagePrefix?: string | undefined
}

export interface RowsPerPageContainerStyledProps extends ThemeProps<any> {
    isMobile?: boolean
}

export interface RowStyledProperties extends ThemeProps<any> {
    firstTab?: string | undefined
    active?: boolean
    lookupField?: boolean
    borderBottom?: boolean
    // MK 06/09/2023
    sideNavOpen?: boolean
    isNested?: TableStyledProps['isNested']
    isSquashed?: TableStyledProps['isSquashed']
    selected?: boolean
    // END OF MK 06/09/2023
}

// gbw 04-Mar
export const flipX = '' // "transform:scaleX(-1);";
export const flipY = '' //"transform:scaleY(-1);";
export const displayTable = 'width: 100%;'

export const MiniLoadingSpinnerStyled = styled.span.attrs((props) => ({
    className: '',
}))`
    color: ${(props) => props.theme.spinner.color};
`

export const bespokeNoHeight = (props: TableStyledProps) => {
    if (props.storagePrefix && (props.storagePrefix + '').startsWith('olive_fe')) {
        if (
            props?.id?.startsWith('table_wrapper_RiskArb') ||
            props?.id?.startsWith('table_wrapper_PreEvent') ||
            props?.id?.startsWith('table_wrapper_HoldCo') ||
            props?.id?.startsWith('table_wrapper_ShareClass') ||
            props?.id?.startsWith('table_wrapper_AllEvents') ||
            props?.id?.startsWith('tbl_content_RiskArb') ||
            props?.id?.startsWith('tbl_content_PreEvent') ||
            props?.id?.startsWith('tbl_content_HoldCo') ||
            props?.id?.startsWith('tbl_content_ShareClass') ||
            props?.id?.startsWith('tbl_content_AllEvents')
        ) {
            return 'height: unset;'
        }
    }
    return undefined
}

export const TableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id,
}))<TableStyledProps>`
    ${(props) => props.width && 'width: ' + props.width + 'px;'};

    ${(props) => props.hide && 'display: none;'}
    ${flipY} // vvvv -20 is padding of 15 + 5
    ${(props) => !props.fixedHeight && (props.isNested || props.isSummaryHeader || props.isSummaryFooter) && 'height: auto;'};

    ${(props) => {
        const bespokeHeight = bespokeNoHeight(props)
        if (bespokeHeight) {
            return bespokeHeight
        }
        if (!props.fixedHeight && !props.isNested && !props.isSummaryHeader && !props.isSummaryFooter) {
            return (
                'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                ((props.startPos || 0) + (props.footerHeight || 0) + 30) +
                'px) !important;'
            )
        }
        return false
    }};

    ${(props) => {
        const bespokeHeight = bespokeNoHeight(props)
        if (bespokeHeight) {
            return bespokeHeight
        }
        if (!props.fixedHeight && !props.isNested && !props.isSummaryHeader && !props.isSummaryFooter) {
            if (props?.startPos && props?.startPos > 500) {
                if (props.isMobile) {
                    return 'height: 700px !important;'
                } else {
                    return undefined
                }
            } else {
                return (
                    'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
                    ((props.startPos || 0) + (props.footerHeight || 0) + 30) +
                    'px) !important;'
                )
            }
        } else if (props.fixedHeight) {
            /* 100 is a guess at table controls height*/
            return 'height: calc(' + props.fixedHeight + 'px - 100' + 'px) !important;'
        } else {
            return undefined
        }
    }};

    overflow-y: auto;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props?.theme?.scrollbar?.thumbBgColor || props.theme.colors.dark[0]};
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props?.theme?.scrollbar?.thumbHoverBgColor || props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props?.theme?.scrollbar?.thumbBorderColor || props.theme.colors.dark[1]};
        border-radius: ${(props) => props?.theme?.scrollbar?.thumbBorderRadius || '10px'};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props?.theme?.scrollbar?.thumbHoverBgColor || props.theme.table.titleBgColor};
    }
`

interface TableHeaderProperties {
    isMobile: boolean
}

export const TableHeaderStyled = styled.tr.attrs((props: TableHeaderProperties) => ({}))<TableHeaderProperties>`
    // vvv shags transactions
    // padding-left: 10px !important;

    // ${(props) => props.isMobile && 'margin-left: 3px;'}
    // ${(props) => !props.isMobile && 'margin-left: 10px;'}
`

export const TableStyled = styled.table.attrs((props: TableStyledProps) => ({
    className: props.className,
}))<TableStyledProps>`
    ${flipY};
    // ${(props) => props.width && !props.isOverFlown && 'width: ' + props.width + 'px;'};

    table-layout: auto;
    border-collapse: collapse;
    ${(props) => {
        if (props.theme.table.gridBorder) {
            return ' border: ' + props.theme.table.gridBorder + ';'
        } else {
            return false
        }
    }}
`

export const TableHeadStyled = styled.thead.attrs((props: TableStyledProps) => ({
    className: props.className,
}))<TableStyledProps>`
    // margin-left: ${(props) => (props.overflown ? '9px' : '')};
    // MK 27/7/23 fix for schedule table... (any tables that don't fill the screen) -> inline-table instead of grid
    // MK 06/09/23 squashed table display:grid

    // display: block;

    ${(props) => props.isOverFlownY && !props.isMobile && 'margin-left: 10px;'}
    ${(props) => props.isOverFlownY && props.isMobile && 'margin-left: 3px;'}

    ${displayTable} // ${(props) => props.width && !props.isOverFlown && 'width: ' + props.width + 'px;'};
`

export const TableBodyStyled = styled.tbody.attrs((props: TableStyledProps) => ({
    className: props.className + (props.isMobile ? ' gusl_body_mobile ' : ' gusl_body '),
    id: props.id ? props.id : '',
}))<TableStyledProps>`
    // display: block;

    // vvv never height, screws up multi table pages like AccountSummary
    ${(props) =>
        !props.isMobile &&
        !props.isNested &&
        'max-height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.bodyStartPos ? props.bodyStartPos : props.tBodyHeight ? props.tBodyHeight + 16 : 0) +
            'px);'};
    ${(props) =>
        props.isMobile &&
        !props.isNested &&
        'max-height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' +
            (props.bodyStartPos ? props.bodyStartPos : props.tBodyHeight ? props.tBodyHeight + 16 : 0) +
            'px);'};

    // ${(props) => props.width && !props.isOverFlown && 'width: ' + props.width + 'px;'};

    overflow-y: auto;
    overflow-x: hidden;
    //&.table_body_new_entry_response {
    //  background-color: red;
    //  max-height: unset;
    //  height: 500px;
    //  width:100%;
    //}

    ${flipX}
    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.panelBgColor};
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
`
export const NoRowMessagesStyled = styled.tr.attrs((props) => ({}))`
    font-size: 14px;
    color: var(--muted);

    float: left;
    ${flipX}
`

/*
  :hover {
    background-color: ${props => {
      if (props.firstTab && !props.selected) {
        return props.theme.table.rowHoverBgColor;
      } else {
        return 'inherit';
      }
    }
    }
  }

 */
export const TableRowStyled = styled.tr.attrs((props: RowStyledProperties) => ({}))<RowStyledProperties>`
    cursor: ${(props) => (props.firstTab ? 'pointer' : 'inherit')};

    ${flipX}

    ${displayTable}
    color: ${(props) => props.theme.table.columnColor};
    font-size: ${(props) => props.theme.table.columnFontSize};

    :nth-of-type(odd) > * {
        // 05/09 removed !important color for void
        color: ${(props) => props.theme.table.columnColor};
        font-size: ${(props) => props.theme.table.columnFontSize};
    }

    ${(props) => props.selected && 'background-color: var(--table-highlight-background-color);'};

    td {
        vertical-align: top;
    }

    &[data-row-key='50'] {
        background-color: ${(props) => props.theme.colors.dark[0]};
    }
`
/*
  //  why, was in ^^^
  border-bottom: 1px solid ${props => props.borderBottom ? props.theme.colors.dark[1] : 'transparent'} !important;

 */
export const RowsPerPageLookupStyled = styled.div.attrs((props) => ({
    id: props.id,
}))`
    z-index: 20;
    width: 60px;
    position: relative;
    left: -50px;
    top: 35px;
    height: 20px;

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.table.panelBgColor};
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.dark[0]};
        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.table.titleBgColor};
    }
`

export const RowsPerPageContainerStyled = styled.div.attrs((props: RowsPerPageContainerStyledProps) => ({
    className: 'd-flex align-items-center justify-content-start mx-2',
}))<RowsPerPageContainerStyledProps>`
    //width: 60px;
    margin-top: ${(props) => (props.isMobile ? '7px' : '7px')} !important;
`
export const RowsPerPageTableResponsiveStyled = styled.div.attrs((props: TableStyledProps) => ({
    className: props.className,
    id: props.id,
}))<TableStyledProps>`
    // ${flipY}   ;
    display: block;
    max-height: 200px;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-right: 0px;
    width: 57px;
`
