import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { SessionContext } from '../../../../providers/session/SessionContext'
import { SystemContext } from '../../../../providers/system/SystemContext'
import { RunOnceEffect, unSubscribe } from '../../../../utils/Utils'
import MaintainTable from '../../../common/maintain-table/MaintainTable'
import { IMenuDTO } from '../../../types'
import ArticleModal from '../article-modal/ArticleModal'
import { RISK_ARB } from '../navigation/SecondaryOttoNav'
import { OTTO_HOME } from '../OttoEvents'
import ottoService from '../ottoService'
import { OttoBoxStyled, OttoCol12, OttoCol3, OttoCol6, OttoRow, ScrollBox } from '../styled'
import GeneralArticles from './general/GeneralArticles'
import { OttoTableState } from './ottoTableSlice'
import PopularArticles from './popular/PopularArticles'
import RecommendedArticles from './recommended/RecommendedArticles'

import { OttoTableContainerStyled, OttoTableLeftContainerStyled, OttoTableRightContainerStyled } from './styled'

interface OttoTableProperties {
    hasLeftPanel?: boolean
}

export default function OttoTable({ hasLeftPanel = true }: OttoTableProperties) {
    const systemContext = useContext(SystemContext)
    const sessionContext = React.useContext(SessionContext)
    const [menuItem, setMenuItem] = useState<IMenuDTO | undefined>(undefined)

    const params = useParams()
    const tabParam = params?.tab ? decodeURIComponent(params?.tab?.toUpperCase()) : undefined
    // vvv one of life's little mysteries
    const articleId = params?.dealId ? decodeURIComponent(params?.dealId) : undefined

    // console.log(`tabParam: ${tabParam} articleId: ${articleId} `)

    const _ottoTableSlice: OttoTableState = useAppSelector((state) => state.ottoTableSlice[tabParam || RISK_ARB])

    const [contentsHeight, setContentsHeight] = useState('150px')
    const [bottomHeight, setBottomHeight] = useState('250px')
    const [bottomHeightInt, setBottomHeightInt] = useState(window.innerHeight - 480)
    const contentsElement = useRef(null)

    const [systemReady, setSystemReady] = useState<boolean>(false)

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            if (systemReady) {
                setMenuItem(ottoService.getMenuConfigForTab(systemContext, tabParam))
            }
        })
        return () => {
            unSubscribe(loaderSubscription)
        }
    })

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (systemReady && contentsElement) {
                    // @ts-ignore
                    const rect = contentsElement?.current?.getBoundingClientRect()
                    if (rect) {
                        let h = window.innerHeight - rect.top - 130
                        // setContentsHeight(Math.round(h * 0.33) + 'px')
                        // setBottomHeight(Math.round(h * 0.73) + 'px')
                        setContentsHeight('180px')
                        setBottomHeight(Math.round(h - 180) + 'px')
                        //setBottomHeightInt(Math.round(h - 180) )
                    }
                }
            }, 100)
        })
    }, [_ottoTableSlice?.refreshCounter || 0])

    const renderLeftPanel = (): React.ReactElement => {
        return (
            <OttoRow>
                <OttoCol6 id={'recommendations'}>
                    <RecommendedArticles
                        code={'recommended'}
                        position="left"
                        contentsHeight={contentsHeight}
                        dealType={tabParam || RISK_ARB}
                    />
                </OttoCol6>
                <OttoCol3>
                    <GeneralArticles code={'general'} position="center" contentsHeight={contentsHeight} dealType={tabParam || RISK_ARB} />
                </OttoCol3>
                <OttoCol3>
                    <PopularArticles code={'popular'} position="right" contentsHeight={contentsHeight} dealType={tabParam || RISK_ARB} />
                </OttoCol3>
            </OttoRow>
        )
    }

    return (
        <OttoTableContainerStyled>
            {systemReady && (
                <div>
                    {hasLeftPanel && (
                        <OttoRow>
                            <OttoCol12>
                                <OttoTableLeftContainerStyled
                                    ref={contentsElement}
                                    key={'otto-table-left-' + (_ottoTableSlice?.refreshCounter || 0)}
                                    id={'otto-table-left-' + (_ottoTableSlice?.refreshCounter || 0)}
                                >
                                    {renderLeftPanel()}
                                </OttoTableLeftContainerStyled>
                            </OttoCol12>
                        </OttoRow>
                    )}
                    <OttoRow>
                        <OttoCol12>
                            {/*{height={hasLeftPanel ? bottomHeight : undefined}}*/}
                            <OttoTableRightContainerStyled>
                                <OttoBoxStyled>
                                    {menuItem && (
                                        <MaintainTable
                                            fixedHeight={bottomHeightInt}
                                            menuItem={menuItem}
                                            hideHeaderPanel={true}
                                            isTabChild={false}
                                        />
                                    )}
                                </OttoBoxStyled>
                            </OttoTableRightContainerStyled>
                        </OttoCol12>
                    </OttoRow>
                </div>
            )}
            {_ottoTableSlice?.currentArticle && articleId && (
                <ArticleModal articleId={articleId || ''} returnUrl={OTTO_HOME + '/' + tabParam} />
            )}
        </OttoTableContainerStyled>
    )
}
