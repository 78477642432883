import styled, { ThemeProps } from 'styled-components'

export const OttoContentStyled = styled.div.attrs((props) => ({
    className: 'g-0 p-2',
    id: 'otto-content',
}))`
    background-color: rgb(243 244 246);
    font-family: Manrope, sans-serif;

    height: 100%;
    flex-direction: column;
    color: rgba(26, 26, 26, 1);
`

interface BoxStyledProps extends ThemeProps<any> {
    level?: string
    className?: string
    position?: 'left' | 'right' | 'center'
    flex?: boolean
}

export const ArticleWrapperStyled = styled.div.attrs((props) => ({
    id: 'article-wrapper',
}))`
    padding: 5px;
    border: solid 1px rgb(101 245 125 / 20%);
    background-color: #ffffff;
`
/*
  background-color: #0a4232;
  background: -moz-linear-gradient(left, #0a4232 0%, #579b88 100%);
  background: -webkit-linear-gradient(left, #0a4232 0%, #579b88 100%);
  background: linear-gradient(to right, #0a4232 0%, #579b88 100%);
  border-radius: 5px;
  border: 1px solid
      ${(props) =>
          props.level === 'WARNING' ? props.theme.colors.warning : props.level === 'INFO' ? props.theme.colors.info : 'inherit'};

 */
export const OttoBoxStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className,
}))<BoxStyledProps>`
    border-radius: 12px;
    background-color: #fff;

    @media (min-width: 992px) {
        ${(props) => props.position === 'left' && 'margin-right: 10px;'};
        ${(props) => props.position === 'right' && 'margin-left: 10px;margin-right: 5px;'}
        ${(props) => props.position === 'center' && 'margin-left: 10px;margin-right: 10px;'}
    }
    padding-bottom: 20px;

    box-shadow: 0 5px 8px -4px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(226, 226, 226, 1);

    .infinite-scroll-component {
        @media (min-width: 992px) {
            ${(props) => props.flex && 'display: flex;'}
            ${(props) => props.flex && 'flex-wrap: wrap;'}
        }
    }

    .otto-link {
        @media (min-width: 992px) {
            ${(props) => props.flex && 'width: 220px;'}
        }
    }
`
/*
    ${(props) => props.position === 'left' && 'margin: 12px 15px 0 0;'}
    ${(props) => props.position === 'right' && 'margin: 12px 0px 0 15px;'}

 */
export const BoxStyled = styled.div.attrs((props) => ({
    id: 'box',
}))``

export const LeftBoxStyled = styled.div.attrs((props) => ({
    id: 'box-left',
}))`
    padding: 0.5rem 1.5rem 0.5rem 0.5rem;
!important;
`

export const RightBoxStyled = styled.div.attrs((props) => ({
    id: 'box-right',
}))`
    padding: 0.5rem 0.5rem 0.5rem 1.5rem !important;
`

export const OttoBoxNoBgStyled = styled.div.attrs((props: BoxStyledProps) => ({
    className: props.className,
}))<BoxStyledProps>`
    padding: 2px 0;
    border-radius: 5px;
`

export const OttoGrid = styled.div.attrs((props) => ({
    className: 'g-0',
}))`
    width: 850px;
`

export const OttoRow = styled.div.attrs((props) => ({
    className: 'row g-0',
}))``

export const OttoCol3 = styled.div.attrs((props) => ({
    className: 'col-lg-3 ',
}))`
    @media (max-width: 992px) {
        padding-bottom: 10px;
    }
`

/*
col-lg-4
 */
export const OttoCol4 = styled.div.attrs((props) => ({
    className: 'col-lg-4',
}))``

export const OttoCol6 = styled.div.attrs((props) => ({
    className: 'col-lg-6 ',
}))`
    @media (max-width: 992px) {
        padding-bottom: 10px;
    }
`
export const OttoCol12 = styled.div.attrs((props) => ({
    className: 'col-lg-12',
}))``

interface ScrollBoxProperties {
    height?: string
    startPos?: number
}

export const ScrollBox = styled.div.attrs((props: ScrollBoxProperties) => ({
    className: '',
}))<ScrollBoxProperties>`
    overflow-y: auto;
    ${(props) => props.height && 'height: ' + props.height + ';'}
    ${(props) => props.startPos && 'height: calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.startPos + 90) + 'px)'};

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }

    ::-webkit-scrollbar-track {
        background-color: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(111, 75, 242, 1);

        border: 1px solid ${(props) => props.theme.colors.dark[1]};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgba(111, 75, 242, 1);
    }
`
/*
 className="lead my-3"
 */
export const ArticleTextStyle = styled.div.attrs((props) => ({
    className: '',
}))`
    font-size: 12px;
    line-height: 20px;
`

export const TabWrapperStyle = styled.div.attrs((props) => ({
    id: 'tab-wrapper',
}))`
    //padding-top: 18px;
    padding-left: 5px;
    margin-bottom: 50px;
`
export const TickerWrapperStyle = styled.div.attrs((props) => ({
    id: 'ticker-main-wrapper',
}))`
    padding-top: 10px;
    padding-left: 5px;
`
