import styled from 'styled-components'

interface ArticleTitle {
    active: boolean
    large?: boolean
}

export const ArticleTitleStyled = styled.div.attrs((props: ArticleTitle) => ({
    className: 'mb-1 otto-link ',
}))<ArticleTitle>`
    // ${(props) => props.active && 'font-size: 12px; font-weight:bold; text-decoration: underline; color: #fff; line-height:20px;'}
    font-size: ${(props) => (props.large ? '14px' : '12px')};
    line-height: ${(props) => (props.large ? '20px' : '20px')};

    border-radius: 5px;
    padding: 5px;
    // background-color: rgb(255 255 255 / 10%);
    background-color: ${(props) => (props.active ? 'rgb(233 229 251)' : 'rgb(249 249 251)')};
    color: rgba(26, 26, 26, 1);
`

export const ArticleDealNameStyled = styled.div.attrs((props) => ({}))`
    text-decoration-color: rgba(255, 240, 246, 0.5);
    text-decoration-line: underline;
    text-underline-offset: 4px;
    font-weight: 600;
    font-size: 14px;
    font-family: Inter, sans-serif;
`

export const ArticleTitleWrapperStyled = styled.div.attrs((props) => ({}))``

export const ArticleTitleDealStyled = styled.span.attrs((props) => ({}))`
    color: rgba(11, 91, 65, 1);
    background-color: rgba(41, 160, 132, 0.3);

    padding: 4px 8px 4px 8px;
    gap: 8px;
    border-radius: 4px;
    font-size: 11px;
    margin-right: 5px;
    font-family: Inter, sans-serif;
`

export const RecommendationsArticleSummaryStyled = styled.div.attrs((props) => ({}))``
export const ArticleSummaryStyled = styled.div.attrs((props) => ({}))`
    margin-bottom: 10px;
`
export const ArticleTimeStyled = styled.span.attrs((props) => ({}))`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-right: 5px;
    //color: rgba(157, 157, 157, 1);
    color: #757575;
    font-family: Inter, sans-serif;
`

interface ArticleSubjectProperties {
    dealPage?: boolean
}

export const ArticleSubjectStyled = styled.div.attrs((props: ArticleSubjectProperties) => ({}))<ArticleSubjectProperties>`
    font-size: 12px;
    display: inline;
    color: rgba(26, 26, 26, 1);
    ${(props) => props.dealPage && 'font-size: 12px;font-weight: 600;'}
    ${(props) => !props.dealPage && 'font-weight: 400;'}

    font-family: Inter, sans-serif;

    h3,
    p {
        color: rgba(157, 157, 157, 1);
    }

    h3 {
        font-weight: bold;
    }
`

export const ArticleInlineHeaderStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    line-height: 20px;
    color: #000000;
`

export const ArticleHeaderStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    line-height: 20px;
    color: rgba(41, 160, 132, 1);
    font-weight: 700;
    font-size: 10px;
`

export const ArticleSubHeaderStyled = styled.div.attrs((props) => ({
    className: '',
}))`
    padding-top: 5px;
    font-size: 16px;
`

interface ArticleDateProperties {
    large?: boolean
}

export const ArticleInlineDateStyled = styled.div.attrs((props: ArticleDateProperties) => ({
    className: 'mb-3',
}))<ArticleDateProperties>`
    font-size: ${(props) => (props.large ? '14px' : '10px')};
    padding-top: 5px;
    color: rgba(161, 161, 170, 1);
`

export const ArticleDateStyled = styled.div.attrs((props: ArticleDateProperties) => ({
    className: 'mb-3',
}))<ArticleDateProperties>`
    font-size: ${(props) => (props.large ? '12px' : '12px')};
    padding-top: 5px;
    //color: rgba(157, 157, 157, 1);
    // color: #757575;
    //font-weight: 600;
    color: rgba(41, 160, 132, 1);
    padding-left: 6px;
    font-family: Inter, sans-serif;
    font-weight: bolder;
`

/*
    className: 'link-text '

 */
export const ArticleInfoStyled = styled.div.attrs((props) => ({}))`
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
`
export const WysiwygTableContentStyled = styled.div.attrs((props) => ({
    className: 'wysiwyg-content',
    id: 'wysiwyg_content',
}))`
    padding: 40px 10px 10px 10px;
`
export const GoToRouteStyled = styled.div.attrs((props) => ({}))`
    font-size: 12px;
    padding-top: 4px;
    line-height: 22px;
    color: #003bff;

    &:hover {
        text-decoration: underline;
    }
`
