import { AxiosResponse } from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { store } from '../../../app/store'
import { BlastContext } from '../../../providers/blast/BlastContext'
import { FieldDeltaDO, SimpleMessageBO } from '../../../providers/blast/commands'
import { EnvironmentContext } from '../../../providers/environment/EnvironmentContext'
import { environmentService } from '../../../providers/environment/EnvironmentService'
import { guslStorage } from '../../../providers/session/GuslStorage'
import { SessionContext } from '../../../providers/session/SessionContext'
import { SystemContext } from '../../../providers/system/SystemContext'
import { HEADER_HEIGHT } from '../../../providers/theme/GuslThemeProvider'
import { log } from '../../../services/LogService'
import { isElementOverFlown } from '../../../utils/CssUtils'
import { arrayNotEmpty, isBlank, isDefined, notBlank, notDefined } from '../../../utils/TypeCheckers'
import {
    areActionsMediaTypeSensitive,
    areFieldsMediaTypeSensitive,
    cancelAbortController,
    compare,
    constructUrl,
    getTableControls,
    matchMediaTypeWithAction,
    matchMediaTypeWithField,
    matchMediaTypeWithRequiredAndSensitivity,
    RunOnceEffect,
    unSubscribe,
} from '../../../utils/Utils'
import { FilterActionItemStyled, FilterActionWrapperStyled } from '../../flex-list/styled'
import {
    ActionConfigDTO,
    ActionType,
    FavQueryActionConfigDTO,
    FavQueryDTO,
    FilterActionControlDO,
    FilterActionDO,
    FormMode,
    IMenuDTO,
    MediaType,
    NotificationPosition,
    NotificationType,
    OrderByDTO,
    QueryParamsDTO,
    SummaryDO,
    SystemNotificationDTO,
} from '../../types'
import { ComponentTitleWrapperStyled } from '../../ui/styled'
import { setActiveAction } from '../action-dialog/actionSlice'
import ElementWithModal from '../element-with/element-with-modal/ElementWithModal'
import MaintainForm from '../maintain-form/MaintainForm'
import Dialog from '../maintain-table/bootstrap/Dialog'
import Icon from '../maintain-table/bootstrap/Icon'
import { maintainTableService } from '../maintain-table/MaintainTableService'
import {
    ActionHeaderColumnStyled,
    ColumnHeaderStyled,
    GuslTableWrapperStyled,
    TableContainerStyled,
    TableTitleContainerStyled,
    TableTitleStyled,
} from '../maintain-table/styled'
import { ExpandedSummaryPanel } from '../maintain-table/summary/ExpandedSummaryPanel'
import {
    cleanupGroupAction,
    clearAllRows,
    GroupActionResponseWrapper,
    GroupActionState,
    initGroupAction,
    sendGroupActionRequest,
} from '../maintain-table/table-row/groupActionsSlice'
import { TableRow } from '../maintain-table/table-row/TableRow'
import { closeAction, ShowActionType, toggleActionItem } from '../maintain-table/table-row/tableRowSlice'
import FavQueryAsHeaderFilter from './fav-queries/FavQueryAsHeaderFilter'
import { queryParamsAreTheSame } from './fav-queries/functions'
import { downloadBlob } from './functions/functions'
import { initColumnResize, TableColumnResizeState } from './guslColumnResizeSlice'
import { GuslTableProperties } from './GuslTable'
import { initTableRefresh } from './guslTableRefreshSlice'
import {
    cleanUp,
    deltaUpdate,
    getTableData,
    GuslTableState,
    incrementCounter,
    initTable,
    setCurrentContentTotal,
    setResponseTotal,
    setServerQueryParameters,
    setServerRangeQueries,
    TableResponseWrapper,
    toggleCreateEntity,
    toggleInline,
    updateFavQueries,
} from './guslTableSlice'
import { initInlineEditSlice, InlineEditState } from './inline-edit/inlineEditSlice'
import TablePagination from './pagination/TablePagination'
import paginationService, { ROWS_PER_PAGE } from './PaginationService'
import {
    GuslTableQueryParamState,
    infinityNextPage,
    initQueryParams,
    refreshListView,
    setInitialOrderBys,
    updateQueryParams,
    updateTotalNumberOfRows,
} from './queryParamsSlice'
import TableSpinner from './spinner/TableSpinner'
import {
    GroupedActionsBlankRowStyled,
    GroupedActionsMenuBarStyled,
    GroupedActionsTextStyled,
    GroupedActionsWrapperStyled,
    ListTableContentStyled,
    TableSpinnerColumnWrapperStyled,
    TableSpinnerRowWrapperStyled,
    TableSpinnerWrapperStyled,
} from './styled'
import {
    NoRowMessagesStyled,
    TableBodyStyled,
    TableHeaderStyled,
    TableHeadStyled,
    TableResponsiveStyled,
    TableStyled,
} from './styled/table/styled'
import { hasFiltersApplied } from './table-controls/side-panel-controls/SidePanelControls'
import TableControls from './table-controls/TableControls'
import TableColumnHeader from './TableColumnHeader'

interface TableState {
    state: GuslTableState
    queryParamsState: GuslTableQueryParamState
    groupActionState: GroupActionState
}

export interface DownloadRequest {
    queryParams: QueryParamsDTO
    fields: string[]
}

export const expandableIconCellWidth = 20

export const groupActionsCellWidth = 50

export const ListView = ({
    code,
    label,
    fields,
    selectUrl,
    tableData,
    tableActions,
    rowActions,
    groupActions,
    isSummaryHeader,
    isSummaryFooter,
    hideHeaderPanel,
    hideHeaderColumns,
    filterPanelOpened,
    headerSummary,
    footerSummary,
    conditionalRowDetails,
    firstTab,
    pathParams,
    createConfig,
    isInSplit,
    height,
    headerUrl,
    blastDeltaCommand,
    highlightDelta,
    disableTableControls,
    disableColumnResize,
    subLabel,
    initialOrderBy,
    isTabChild,
    orderByActions,
    widgetPanelProperties,
    headerPanels,
    fixedHeight,
    isCascadingTable = false,
    parentData,
}: GuslTableProperties): React.ReactElement => {
    const sessionContext = React.useContext(SessionContext)
    const systemContext = React.useContext(SystemContext)
    const blastContext = useContext(BlastContext)
    const environmentContext = React.useContext(EnvironmentContext)
    const navigate = useNavigate()
    // MK 07/09/2023 -> added as dependency to useEffect to fix issue, when you resize header summary table
    const guslColumnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])

    // console.log(`---- listView: ${code} ----`)
    const columnsResetAt: number | null = guslColumnResizeState?.columnsResetAt

    function isSquashedHandler() {
        return matchMediaTypeWithRequiredAndSensitivity(
            environmentService?.getEnvironment()?.squashedTableDisplay || MediaType.Never,
            environmentContext.getCurrentMediaType() || MediaType.Never,
            true
        )
    }

    // MK 06/09/2023 isSquashed table
    const [isSquashed, setIsSquashed] = useState<boolean>(() => {
        return isSquashedHandler()
    })
    // MK 30-12-2023
    useEffect(() => {
        if (columnsResetAt) {
            setIsSquashed(isSquashedHandler())
        }
    }, [columnsResetAt])

    const isNested: boolean = isCascadingTable

    const _state: GuslTableState = useAppSelector((state) => state.guslTableSlice[code])
    const _groupActionState: GroupActionState = useAppSelector((state) => state.groupActionsSlice[code])
    const _queryParamsState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    // MK 05-01-2024
    // const currentQuery: QueryParamsDTO = _queryParamsState?.queryParams;
    // const currentQuery: QueryParamsDTO = _state?.serverQueryParams || paginationService.blankQueryParam()
    const [serverQueryParams, setServerQueryParams] = useState<QueryParamsDTO>()

    // MK 04-01-2024
    const queryParamsResetAt: number | undefined = _queryParamsState?.queryParams.resetAt
    useEffect(() => {
        if (queryParamsResetAt) {
            setActiveFilterAction(undefined)
        }
    }, [queryParamsResetAt])

    const favQueriesUpdatedAt: number | undefined = _state?.favQueriesUpdatedAt

    const [favouriteQueries, setFavouriteQueries] = useState<FavQueryDTO[]>([])
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState('ListView-' + new Date().getTime())
    const dispatch = useAppDispatch()
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [newLoading, setNewLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const queryParamState: GuslTableQueryParamState = useAppSelector((state) => state.queryParamsSlice[code])
    const [systemReady, setSystemReady] = useState<boolean>(false)
    const [firstLoadDone, setFirstLoadDone] = useState<boolean>(isSummaryHeader || isSummaryFooter ? isDefined(tableData) : false)
    const favQueriesURLs: FavQueryActionConfigDTO = environmentService.getEnvironment()?.favQueriesUrls as FavQueryActionConfigDTO
    const pagedUrl: string = favQueriesURLs?.pagedUrl || 'needToDoBackoffice'
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [searchParams, setSearchParams] = useSearchParams()
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [tableHeight, setTableHeight] = useState('auto')
    const tableElement = useRef(null)
    const contentElement = useRef(null)
    const guslWrapperElement = useRef(null)
    const [guslWrapperStartPos, setGuslWrapperStartPos] = useState(73)
    const tableWrapperElement = useRef(null)
    const [tableWrapperStartPos, setTableWrapperStartPos] = useState(73)

    const [bodyHeight, setBodyHeight] = useState(height)
    const [bodyStartPos, setBodyStartPos] = useState(height)
    const tableBodyElement = useRef(null)
    const [bodyWidth, setBodyWidth] = useState<number>(window?.innerWidth ? window?.innerWidth - 50 : 300)
    const [tableStartPos, setTableStartPos] = useState<number>(HEADER_HEIGHT)
    // MK 25/09/2023 // MK 27/09/2023 added withLimit to persist rows per table
    const [initialised, setInitialised] = useState<boolean>(hasFiltersApplied(queryParamState?.queryParams, true))
    //const showMobileContent = _state?.showMobileTableControls;
    const _columnResizeState: TableColumnResizeState = useAppSelector((state) => state.guslColumnResizeSlice[code])
    const currentlyResizingHeaderName: string | undefined = _columnResizeState?.resizeColumnFieldName
    // MK 17-02-2024
    const inlineEditState: InlineEditState = useAppSelector((state) => state.inlineEditSlice[code])
    // MK 25-02-2024
    const inlineEdit: boolean = inlineEditState?.inlineEdit
    const inlineEditOffAt: number = inlineEditState?.offAt

    const [numberOfActions] = useState<number>(tableActions?.length || 0)
    const [shouldPauseRefresh, setShouldPauseRefresh] = useState<boolean>(false)
    const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType(widgetPanelProperties))

    const [panelData, setPanelData] = useState<any | undefined>(undefined)
    const [headerRefreshRate, setHeaderRefreshRate] = useState<number>(-1)
    const [refreshCounter, setRefreshCounter] = useState<number>(0)
    const [menuItem] = useState<IMenuDTO | undefined>(systemContext.getMenuConfig(code))

    const [defaultOrderBys] = useState<OrderByDTO | undefined>(
        initialOrderBy ? initialOrderBy : maintainTableService.extractInitialOrderBy(menuItem)
    )
    const [isOverFlownX, setOverFlownX] = useState<boolean>(true)
    const [isOverFlownY, setOverFlownY] = useState<boolean>(true)
    const [oldResize, setOldResize] = useState<number | undefined>()
    const [hide, setHide] = useState<boolean>(false)
    const [filterActionControl, setFilterActionControl] = useState<FilterActionControlDO | undefined>(undefined)
    const [activeFilterAction, setActiveFilterAction] = useState<FilterActionDO | undefined>(undefined)
    const [displayFavQueriesWithHeaderFilters, setDisplayFavQueriesWithHeaderFilters] = useState<boolean>(false)
    const [noRowsMessage, setNoRowsMessage] = useState<string | undefined>(undefined)
    const [colSpan, setColSpan] = useState<number>(1)

    const [footerHeight, setFooterHeight] = useState<number>(0)
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription)
        }
    })

    const topScrollBarPositionRef = useRef(0)
    const tableWrapperId = 'table_wrapper_' + code
    const tableBodyId = 'table_body_' + code

    const guslTableWrapperId = 'gusl_table_wrapper_' + code

    // MK 29-12-2023  to help table not to go bananas when resizing columns...;-)
    // isSquashed => 'display: grid;'
    // || code === "Logs" does not have to be there, but it is better user experience on first resize
    useEffect(() => {
        // setIsSquashed(true);
        // setIsSquashed(isSquashedHandler());
        if ((currentlyResizingHeaderName && !isNested) || code === 'Logs' || (_state?.haveRowActions && !isNested)) {
            setIsSquashed(true)
        }
        if (isNested) {
            setIsSquashed(false)
        }
    }, [code, currentlyResizingHeaderName, _state])

    useEffect(() => {
        if (_state) {
            const mediaTypeSensitive = areFieldsMediaTypeSensitive(_state.allFields)
            let count = 0
            _state.allFields
                .filter((fld) => fld.displayInTable)
                .filter((fld) => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                .forEach((fld) => count++)

            if (_state.haveGroupActions) {
                count++
            }
            if (_state.haveRowActions) {
                count++
            }
            setColSpan(count)
        }
    }, [_state])

    const tableContentId = 'tbl_cont_' + code
    const sideNavOpen = useAppSelector((state) => state.uiSlice.isSideNavOpen)
    const sideNavClosedAt = useAppSelector((state) => state.uiSlice.sideNavClosedAt)

    const filtersOpen: boolean = _state?.showFilters
    const filtersClosedAt: number = useAppSelector((state) => state.uiSlice.filtersClosedAt)

    const columnSettingsOpen: boolean = _state?.showColumnsSettings
    const columnSettingsClosedAt: number = useAppSelector((state) => state.uiSlice.columnSettingsClosedAt)

    // MK 15/08/2023  when sidenav is open and then when you flip
    // between the tables and then close again, table stays with reduced width due to
    // side nav open
    // MK 31/08/2024 -> added filters and column settings
    useEffect(() => {
        const shouldRefresh: boolean =
            (!sideNavOpen && sideNavClosedAt !== 0) ||
            (!inlineEdit && inlineEditOffAt !== 0) ||
            (!filtersOpen && filtersClosedAt !== 0) ||
            (!columnSettingsOpen && columnSettingsClosedAt !== 0)

        if (shouldRefresh) {
            setRefreshCounter(refreshCounter + new Date().getTime())
            dispatch(incrementCounter({ code }))
            dispatch(refreshListView({ code }))
        }
    }, [
        sideNavOpen,
        sideNavClosedAt,
        inlineEdit,
        inlineEditOffAt,
        filtersOpen,
        filtersClosedAt,
        columnSettingsOpen,
        columnSettingsClosedAt,
    ])

    dispatch(setInitialOrderBys({ code, initialOrderBys: defaultOrderBys }))

    // useEffect(() => {
    //     if (!loading) {
    //         setTimeout(() => {
    //             setHide(false);
    //         }, 300);
    //     }
    // }, [loading]);

    RunOnceEffect(() => {
        const subscriptionOrientation = environmentContext.watchOrientation().subscribe((orientation: string) => {
            setRefreshCounter(refreshCounter + new Date().getTime())
            dispatch(incrementCounter({ code }))
            dispatch(refreshListView({ code }))
        })
        return () => {
            unSubscribe(subscriptionOrientation)
        }
    })

    useEffect(() => {
        const subscriptionResize = environmentContext.watchResize().subscribe((value: number) => {
            // console.log('----------- resize', oldResize, value, !_state?.tableDeltaUpdateActive, !oldResize, oldResize !== value, (!oldResize || oldResize !== value))
            if (!oldResize || oldResize !== value) {
                // console.log('----------- here ->', value, _state?.tableDeltaUpdateActive)

                if (isDefined(_state?.tableDeltaUpdateActive) && !_state?.tableDeltaUpdateActive) {
                    // card is open do not refresh
                    return
                }

                // MK 16/08/2023 don't know how else to prevent closing of live position order on mobile
                if (!isMobile) {
                    setOldResize(value)
                    setRefreshCounter(refreshCounter + new Date().getTime())
                    dispatch(incrementCounter({ code }))
                    dispatch(refreshListView({ code }))
                }
            }
        })

        const subscriptionMediaType = environmentContext.watchMediaType().subscribe((mediaType: MediaType) => {
            if (!widgetPanelProperties) {
                setMediaType(mediaType)
            }
        })
        return () => {
            unSubscribe(subscriptionMediaType)
            unSubscribe(subscriptionResize)
        }
    }, [])

    // MK 06/09/2023 a bit weird one... even if table is visibly overflown,
    // when isSquashed=true -----> display:grid....
    // it is not set as overflown ...
    // so we're adding stoppedLoadingAt into dependency array in useEffect, where we are setting setIsOverFlownX...
    // a few ms later...
    // to see it comment out setTimeout(setX, 200) and set isSquashed=true
    const [stoppedLoadingAt, setStoppedLoadingAt] = useState<number>(0)
    useEffect(() => {
        if (!_state?.loading && isSquashed) {
            function _setStoppedLoadingAt() {
                setStoppedLoadingAt(new Date().getTime())
            }

            setTimeout(_setStoppedLoadingAt, 200)
        }
    }, [code, _state?.loading, isSquashed])
    // END OF MK 06/09/2023

    // MK 07/09/2023 -> added guslColumnResizeState to dependency array
    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                const tableWrapperDiv = document.getElementById(tableWrapperId)
                const tableBody = document.getElementById(tableBodyId)
                const guslTableWrapperDiv = document.getElementById(guslTableWrapperId)
                const tableContentDiv = document.getElementById(tableContentId)

                const overFlownX = isElementOverFlown({
                    element: tableWrapperDiv,
                    direction: 'x',
                })
                const overFlownY = isElementOverFlown({
                    element: tableBody,
                    direction: 'y',
                })

                setOverFlownX(overFlownX)
                setOverFlownY(overFlownY)

                // @ts-ignore
                if (tableElement?.current?.offsetHeight) {
                    // @ts-ignore
                    setTableHeight(tableElement.current.offsetHeight)
                }
                if (tableBodyElement) {
                    // @ts-ignore
                    const rect = tableBodyElement?.current?.getBoundingClientRect()
                    if (rect) {
                        let h = window.innerHeight - rect.top
                        if (isMobile) {
                            if (systemContext.hasMobileFooter()) {
                                h = h - footerHeight - 3
                            } else {
                                h = h - 3
                            }
                        } else {
                            h = h - 20
                        }
                        setBodyHeight(h > 0 ? h : 500)
                        setBodyStartPos(rect.top)
                    } else {
                        setBodyHeight(500)
                    }
                }
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setTableStartPos(rect.y)
                        if (isMobile) {
                            setBodyWidth(rect.width - 5)
                        } else {
                            // back to -60 AccountDetail
                            setBodyWidth(rect.width - 60) //  - 60
                        }
                    }
                }

                if (guslWrapperElement) {
                    // @ts-ignore
                    const rect = guslWrapperElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setGuslWrapperStartPos(rect.y)
                    }
                }
                if (tableWrapperElement) {
                    // @ts-ignore
                    const rect = tableWrapperElement?.current?.getBoundingClientRect()
                    if (rect) {
                        setTableWrapperStartPos(rect.y)
                    }
                }
            }, 100)
        })
    }, [
        _state?.loading,
        hide,
        newLoading,
        contentElement,
        guslWrapperElement,
        tableWrapperElement,
        oldResize,
        tableBodyElement,
        stoppedLoadingAt,
        guslColumnResizeState,
    ]) // , _state?.refreshCounter

    useEffect(() => {
        let loaded = true
        let blastDeltaSubscription: Subscription
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
            // console.log(` code: ${code} systemReady ${systemReady} loaded ${loaded} blastDeltaCommand ${blastDeltaCommand}`)
            if (systemReady && loaded && blastDeltaCommand) {
                blastDeltaSubscription = blastContext
                    .observeInboundCommands()
                    .subscribe((message: SimpleMessageBO<FieldDeltaDO | string>) => {
                        if (systemReady && message) {
                            // @ts-ignore
                            if (blastDeltaCommand && message.cmd === 'table.delta' && message?.data?.tableKey === blastDeltaCommand) {
                                // @ts-ignore
                                const delta: FieldDeltaDO = message.data
                                dispatch(
                                    deltaUpdate({
                                        code: code,
                                        fieldDelta: delta,
                                    })
                                )
                            } else if (message.cmd === 'table.refresh') {
                                // console.log('--------- table refresh ---', code, message.data, code === message?.data)
                                if (code === message?.data) {
                                    dispatch(refreshListView({ code }))
                                }
                            }
                        }
                    })
            }
        })
        return () => {
            loaded = false
            unSubscribe(loaderSubscription)
            unSubscribe(blastDeltaSubscription)
        }
    }, [code, blastDeltaCommand])

    useEffect(() => {
        let refreshRate: number = -1
        let subscription: Subscription | undefined = undefined
        if (systemReady) {
            setActiveFilterAction(undefined)
            refreshRate = maintainTableService.extractRefreshRate(menuItem)

            subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                if (!shouldPauseRefresh && nextRefreshRate !== -1 && refreshRate === nextRefreshRate) {
                    dispatch(refreshListView({ code }))
                }
            })
            if (refreshRate > 0) {
                sessionContext.registerRefreshRate(refreshRate)
            }
        }
        return () => {
            sessionContext.unregisterRefreshRate(refreshRate)
            if (headerRefreshRate) {
                sessionContext.unregisterRefreshRate(headerRefreshRate)
            }
            unSubscribe(subscription)
        }
    }, [systemReady, menuItem])

    const [currentAbortController, setCurrentAbortController] = useState<AbortController | undefined>(undefined)
    const [downloadAbortController, setDownloadAbortController] = useState<AbortController | undefined>(undefined)

    const routeQueryParams = searchParams.get('queryParams')

    const options = {
        // threshold: [0, 1.0],
        threshold: [1.0],
        // trackVisibility: true,
        // delay: 100,
    }

    const _intersectionObserver = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0]
            // @ts-ignore
            console.log('-- IntersectionObserver --', first?.isIntersecting, _state?.hasInfinityScroll, first?.isVisible)
            if (first?.isIntersecting) {
                // && first.isVisible
                // if (_state?.hasInfinityScroll) {
                console.log('-- fetching data --')
                dispatch(infinityNextPage({ code: code }))
                // const qp: QueryParamsDTO = _state?.lastQueryParams || paginationService.blankQueryParam()
                // const skip = qp.skip + qp.limit
                // console.log('==========skip', skip)
                // refreshData({...qp, skip: skip})
                //}
            }
        }, options)
    )

    const lastTableRowElement = useRef(null)

    useEffect(() => {
        let currentElement: any = undefined
        let currentObserver: IntersectionObserver
        let animationFrameId: number | null | undefined
        if (_state?.hasInfinityScroll) {
            animationFrameId = window.requestAnimationFrame(() => {
                setTimeout(() => {
                    currentElement = lastTableRowElement
                    currentObserver = _intersectionObserver.current

                    if (currentElement && isDefined(currentElement.current) && currentObserver) {
                        currentObserver.observe(currentElement.current)
                    }
                }, 100)
            })
        }
        return () => {
            try {
                if (isDefined(animationFrameId)) {
                    window.cancelAnimationFrame(animationFrameId!)
                }
                if (currentObserver && currentElement && isDefined(currentElement.current)) {
                    currentObserver.unobserve(currentElement.current)
                }
            } catch (error: any) {
                console.log('Failed to clean up list view', error)
            }
        }
    }, [lastTableRowElement?.current, _state?.loading, _state?.hasInfinityScroll])

    function initDefaultFavQueryHandler(queryParams: QueryParamsDTO) {
        dispatch(
            updateQueryParams({
                code,
                queryParams,
            })
        )
    }

    function blankQueryParamsHandler() {
        let blankQueryParam: QueryParamsDTO = paginationService.blankQueryParam()
        if (defaultOrderBys) {
            blankQueryParam.orderBys = [
                {
                    field: defaultOrderBys.field,
                    // order: defaultOrderBys.direction
                    order: defaultOrderBys.order,
                },
            ]
        }

        return blankQueryParam
    }

    useEffect(() => {
        if (_queryParamsState?.queryParams?.should && _queryParamsState?.queryParams?.should?.length > 0) {
            return
        }
    }, [systemReady, code])

    useEffect(() => {
        if (systemReady && menuItem) {
            sessionContext
                .post<any, SystemNotificationDTO>(pagedUrl, {
                    musts: [{ field: 'code', value: code }],
                })
                .then((response: any) => {
                    const favQueries: FavQueryDTO[] = response.data.content
                    // MK 11-01-2024
                    dispatch(updateFavQueries({ code, favQueries }))
                    setFavouriteQueries(favQueries)
                    if (favQueries.length) {
                        const defaultFavQuery: FavQueryDTO | undefined = favQueries.find((favQuery) => favQuery.isDefault)
                        if (defaultFavQuery) {
                            initDefaultFavQueryHandler(defaultFavQuery.queryParams)
                        }
                        // MK 27/09/2023
                    } else if (!initialised) {
                        initDefaultFavQueryHandler(blankQueryParamsHandler())
                    }
                })
                .catch((reason: any) => {
                    console.error('Action ', reason)
                })
        }
    }, [systemReady, code, favQueriesUpdatedAt])

    const openCreateNewEntityDialog = () => {
        setPanelData({})
        dispatch(toggleCreateEntity({ code: code }))
    }

    useEffect(() => {
        let abortController = new AbortController()
        if (systemReady) {
            dispatch(
                initTableRefresh({
                    code: code,
                    openModalActionId: '',
                    openModalRowId: '',
                    openInlineActionId: '',
                    openInlineRowId: '',
                    openExpandedRowId: '',
                })
            )
            dispatch(
                initGroupAction({
                    code: code,
                    haveGroupActions: arrayNotEmpty(groupActions),
                })
            )
        }
        topScrollBarPositionRef.current = 0
        return () => {
            abortController.abort()
        }
    }, [code, systemReady])

    useEffect(() => {
        let abortController = new AbortController()

        // gbw vvv added back in - positions loading twice
        if (systemReady) {
            dispatch(initQueryParams({ code: code, queryParams: blankQueryParamsHandler() }))
            // console.log(`---- initTable: ${code} ----`)

            dispatch(
                initTable({
                    code: code,
                    sessionContext: sessionContext,
                    conditionalRowDetails: conditionalRowDetails,
                    label: label,
                    tableData: tableData,
                    selectUrl: selectUrl || '',
                    hideHeaderPanel: hideHeaderPanel || false,
                    hideHeaderColumns: hideHeaderColumns || false,
                    allFields: fields || [],
                    headerSummary: headerSummary,
                    footerSummary: footerSummary,
                    filterPanelOpened: filterPanelOpened || false,
                    tableActions: tableActions || [],
                    rowActions: rowActions || [],
                    groupActions: groupActions || [],
                    orderByActions: orderByActions || [],
                    expandableRow: false,
                    rowsPerPage: ROWS_PER_PAGE[0],
                    onRefresh: undefined,
                    createConfig: createConfig,
                    onOpenCreateNewEntityDialog: openCreateNewEntityDialog,
                    onInlineMenuGroupAction: onInlineMenuGroupAction,
                    performDownloadWithoutPromptAction: performDownloadWithoutPromptAction,
                    performActionOnly: performActionOnly,
                    showFilters: false,
                    // MK 11/08/2023
                    showTopFilters: false,
                    showColumnsSettings: false,
                    openFilterBlocks: [],
                    serverRangeQueries: [],
                    editRowId: '',
                    searchString: '',
                    highlightDelta: highlightDelta,
                    disableTableControls: disableTableControls,
                    disableColumnResize: disableColumnResize,
                    subLabel: subLabel,
                    scrollLeftPosition: 0,
                    favQueriesOpen: false,
                    showMobileTableControls: false,
                    mediaType: mediaType,
                    tableControl: maintainTableService.extractTableControls(menuItem),
                    // resizedColumns: guslStorage.getResizedColumns(code),
                    // resizeColumnFieldName: undefined,

                    // MK 12/08/2023
                    responseTotal: 0,
                    // END OF 12/08/2023
                    individualAdvancedSearchFields: [],
                    favQueriesUpdatedAt: undefined,
                    favQueries: favouriteQueries,
                    headerPanels: headerPanels || [],
                    serverQueryParams: undefined,
                    currentContentTotal: 0,
                    isCascadingTable: isCascadingTable,
                })
            )
            dispatch(
                initColumnResize({
                    code: code,
                    resizedColumns: guslStorage.getResizedColumns(code),
                    columnsResetAt: null,
                })
            )
            dispatch(
                initInlineEditSlice({
                    code,
                    fieldsInEditMode: [],
                    rowsInEditMode: [],
                    inlineEdit: false,
                    originalRows: [],
                    multiSelectOptions: [],
                    lookupOptions: [],
                    offAt: 0,
                    defaultMultiSelectOptions: [],
                })
            )

            if (routeQueryParams) {
                const parsed = JSON.parse(routeQueryParams as string)
                parsed.skip = 0
                parsed.limit = 0
                refreshData(parsed)
            } else {
                dispatch(
                    initQueryParams({
                        code: code,
                        queryParams: blankQueryParamsHandler(),
                    })
                )
            }
            setInitialised(true)

            if (isBlank(selectUrl)) {
                setFirstLoadDone(true)
            }
        }
        return () => {
            cancelAbortController(abortController)
            cancelAbortController(currentAbortController)
            cancelAbortController(downloadAbortController)

            dispatch(cleanUp({ code: code }))
            dispatch(cleanupGroupAction({ code: code }))
        }
    }, [code, systemReady])

    const [doneFirstRun, setDoneFirstRun] = useState<boolean>(false)
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let mounted = true
        let abortController = new AbortController()
        const runFirstPagedQuery: boolean = queryParamState && !routeQueryParams && !doneFirstRun
        // MK 19-08-2024 to prevent double call
        // console.log(`get data => systemReady: ${systemReady} initialised: ${initialised} runFirstPagedQuery: ${runFirstPagedQuery} firstRun: ${firstRun} queryParams match: ${queryParamState?.queryParams !== blankQueryParamsHandler()}`)
        if (systemReady && initialised) {
            if (runFirstPagedQuery) {
                // console.log(`here 1 => runFirstPagedQuery: ${runFirstPagedQuery}`)
                setDoneFirstRun(true)
                refreshData(queryParamState?.queryParams)
            } else if (queryParamState?.queryParams && queryParamState?.queryParams !== blankQueryParamsHandler() && doneFirstRun) {
                // console.log(`here 2 => queryParams not default : ${queryParamState?.queryParams !== blankQueryParamsHandler()} doneFirstRun: ${doneFirstRun}`,queryParamState?.queryParams)
                refreshData(queryParamState?.queryParams)
            }
            // addition of original condition +  -> window.location.pathname.length
            else if ((queryParamState && !routeQueryParams && window.location.pathname.split('/').length === 4) || isCascadingTable) {
                // console.log('---- here 3')
                refreshData(initialised ? queryParamState.queryParams : blankQueryParamsHandler())
            }
        }

        return () => {
            mounted = false
            cancelAbortController(abortController)
        }
    }, [queryParamState?.queryParams, systemReady, routeQueryParams, label, code, initialised, doneFirstRun])

    const refreshData = (queryParams: QueryParamsDTO) => {
        // console.log(`---- refreshData: ${selectUrl} ----`, queryParams)

        const abortController = new AbortController()
        setCurrentAbortController(abortController)
        if (notBlank(selectUrl)) {
            setNewLoading(true)
            dispatch(
                getTableData({
                    code: code,
                    url: selectUrl || '',
                    pathParams: pathParams,
                    queryParams: queryParams,
                    sessionContext: sessionContext,
                    abortController: abortController,
                })
            )
                .unwrap()
                .then((reply: TableResponseWrapper) => {
                    setFirstLoadDone(true)
                    setServerQueryParams(reply?.response.queryParams)
                    dispatch(updateTotalNumberOfRows({ code, total: reply?.response?.total || 0 }))
                    if (reply.response?.filterAction) {
                        const filterActionControl = reply.response?.filterAction
                        if (filterActionControl && filterActionControl.filterActions) {
                            filterActionControl.filterActions.sort(
                                (a: FilterActionDO, b: FilterActionDO) => (a?.displayOrder || 0) - (b?.displayOrder || 0)
                            )
                        }
                        if (filterActionControl?.includeFavQueries) {
                            setDisplayFavQueriesWithHeaderFilters(true)
                        }
                        setFilterActionControl(filterActionControl)
                    }
                    if (reply.response?.noRowsMessage) {
                        setNoRowsMessage(reply.response?.noRowsMessage)
                    }

                    setNewLoading(false)
                    setErrorMessage('')
                    dispatch(
                        setServerRangeQueries({
                            code,
                            serverRangeQueries: reply.response?.queryParams?.rangeQueries,
                        })
                    )

                    dispatch(
                        setServerQueryParameters({
                            code,
                            serverQueryParams: reply.response?.queryParams,
                        })
                    )
                    dispatch(
                        setCurrentContentTotal({
                            code,
                            currentContentTotal: reply.response.content.length,
                        })
                    )

                    dispatch(setResponseTotal({ code, responseTotal: reply.response.total }))
                    dispatch(cleanupGroupAction({ code: code }))

                    // const rowId: number = reply.response.queryParams.limit // - reply.response.queryParams.skip
                    // console.log('rowId', rowId, reply.response.queryParams)
                    // if (rowId > 0 && rowId !== 0) {
                    //     const row: Element | null = document.querySelector(`[data-row-key="${rowId}"]`);
                    //     console.log('---> row', rowId, `[data-row-key="${rowId}"]`, row)
                    //     if (row) {
                    //         row.scrollIntoView({behavior: "smooth", block: "center"});
                    //     }
                    // }
                })
                .catch((error: any) => {
                    console.error('error', error)
                    setNewLoading(false)
                })
        }
    }

    const renderSummary = (code: string, tableState: TableState, isHeader: boolean): React.ReactElement => {
        if ((isHeader && tableState.state.headerSummary) || (!isHeader && tableState.state.footerSummary)) {
            // @ts-ignore
            const summary: SummaryDO = isHeader ? tableState.state.headerSummary : tableState.state.footerSummary
            return (
                <ExpandedSummaryPanel
                    key={code + '_' + tableState.state.refreshCounter}
                    code={code}
                    summary={{ ...summary }}
                    isSummaryHeader={isHeader}
                    isSummaryFooter={!isHeader}
                />
            )
        } else {
            return <></>
        }
    }

    const renderInlinePanel = (tableState: TableState): React.ReactElement => {
        const state: GuslTableState = tableState.state

        const hideInLinePanel = (refresh?: boolean) => {
            dispatch(toggleInline({ code: state.code, menuItem: undefined }))
            if (refresh) {
                refreshData(tableState.queryParamsState.queryParams)
            }
            setShouldPauseRefresh(false)
        }

        const action: ActionConfigDTO | undefined = state?.inlineAction?.activeItem?.action || undefined

        if (!(state.inlineAction.showInline && state.inlineAction.activeItem)) {
            return <></>
        }
        setShouldPauseRefresh(true)
        return (
            <div className={'row g-0'}>
                <div className="col-md-12">
                    <MaintainForm
                        isDialog={false}
                        controlOnTop={false}
                        canEdit={maintainTableService.getCanEditForAction(action)}
                        formMode={maintainTableService.getFormModeForAction(action)}
                        data={{}}
                        menuItem={action && maintainTableService.convertToMenu(undefined, action)}
                        minModalWidth={action?.minModalWidth}
                        minModalHeight={action?.minModalHeight}
                        closeDialog={hideInLinePanel}
                    />
                </div>
            </div>
        )
    }

    const onInlineMenuGroupAction = (event: React.MouseEvent, item: IMenuDTO) => {
        dispatch(toggleInline({ code: code, menuItem: item }))
    }

    const performActionOnly = (action?: ActionConfigDTO, rowData?: any) => {
        log.info(className, 'MSG004', 'performActionOnly', action)
        if (action?.url) {
            dispatch(
                toggleActionItem({
                    rowId: action.rowId,
                    actionItem: undefined,
                    showType: ShowActionType.NO_ACTION,
                })
            )
            const abortController = new AbortController()
            setDownloadAbortController(abortController)
            try {
                sessionContext
                    .post<any, SystemNotificationDTO>(constructUrl(action.url, rowData), {}, abortController)
                    .then((response: any) => {
                        const queryParamsState = store.getState().queryParamsSlice[code]
                        if (queryParamsState?.queryParams) {
                            refreshData(queryParamsState?.queryParams)
                        }
                        if (response.data?.notificationMessage && !response.data?.hideNotification) {
                            systemContext.toast({
                                type: NotificationType.SUCCESS,
                                position: NotificationPosition.BOTTOM_RIGHT,
                                noIcon: false,
                                noAutoClose: false,
                                message: response.data?.notificationMessage,
                                autoCloseDuration: 2000,
                            })
                        } else {
                            systemContext.toast(response.data)
                        }
                    })
                    .catch((reason: any) => {
                        console.error('Action ', reason)
                    })
            } catch (err) {
                log.error(className, 'ERR001', 'performActionOnly error', action, err)
            }
        }
    }

    const performDownloadWithoutPromptAction = (action?: ActionConfigDTO, rowData?: any) => {
        if (action) {
            dispatch(closeAction({ rowId: action.rowId })) // clean the action

            const tableState: GuslTableState = store.getState().guslTableSlice[code]
            const queryParamState: GuslTableQueryParamState = store.getState().queryParamsSlice[code]
            const abortController = new AbortController()
            setDownloadAbortController(abortController)
            const fields: string[] = tableState.allFields.filter((fld) => fld.displayInTable).map((fld) => fld.name)
            sessionContext
                .download<DownloadRequest, any>(
                    constructUrl(action.url, rowData),
                    {
                        queryParams: queryParamState.queryParams,
                        fields: fields,
                    },
                    abortController
                )
                .then(
                    (response: AxiosResponse<Response>) => {
                        const contentDisposition = response.headers['content-disposition']
                        let filename = response.headers['export-filename']
                        const contentType = response.headers['content-type']

                        if (contentDisposition) {
                            let startFileNameIndex = contentDisposition.indexOf('"') + 1
                            let endFileNameIndex = contentDisposition.lastIndexOf('"')
                            filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex)
                        }

                        downloadBlob(filename, contentType, response.data)
                    },
                    (reason: any) => {
                        console.error('Download error ', reason)
                    }
                )
        }
    }

    const renderCreateNewEntityDialog = (tableState: TableState): React.ReactElement => {
        const state: GuslTableState = tableState.state

        const hideCreateNewEntityDialog = (refresh?: boolean) => {
            dispatch(toggleCreateEntity({ code: state.code }))
            if (refresh) {
                refreshData(tableState.queryParamsState.queryParams)
            }
        }
        return (
            <>
                {tableState.state.createEntityOpened && (
                    <div className={'row g-0'} key={'new_form_div_1'} id={'new_form_div_1'}>
                        <div className="col-md-12" key={'new_form_div_2'}>
                            <Dialog
                                header={state?.label || ''}
                                key={'new_form'}
                                isVisible={state.createEntityOpened}
                                onClose={() => hideCreateNewEntityDialog(false)}
                            >
                                <MaintainForm
                                    formMode={FormMode.NEW}
                                    key={'new_maintain_form'}
                                    isDialog={false}
                                    controlOnTop={true}
                                    canEdit={true}
                                    data={panelData}
                                    menuItem={tableState.state.createConfig}
                                    closeDialog={hideCreateNewEntityDialog}
                                />
                            </Dialog>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const renderActionBar = (tableState: TableState): React.ReactElement => {
        // console.log('parentData', code, parentData)
        return (
            <div key={'div_1_' + code} className={'row g-0'} id={'gt_1_' + code}>
                <div key={'div_2_' + code} className={'col order-last order-md-first '} id={'gt_' + code}>
                    <TableControls
                        key={code + '-tc'}
                        code={code}
                        label={label}
                        state={tableState?.state}
                        isLoading={false}
                        isHeader={false}
                        isFooter={false}
                        haveFilters={tableState?.state?.haveFilters || false}
                        pagination={<TablePagination key={code + '-table-pagination'} code={code} />}
                        orderByActions={tableState?.state?.orderByActions}
                        hasAdvancedFilters={false}
                        tableMenuGroups={tableState?.state?.tableMenuGroups}
                        rowData={parentData}
                    />
                </div>
            </div>
        )
    }

    const renderTableLabel = (tableState: TableState): React.ReactElement => {
        if (
            !tableState.state.subLabel ||
            isSummaryHeader ||
            isSummaryFooter ||
            (!tableState.state.headerSummary && !tableState.state.footerSummary)
        ) {
            return <></>
        }
        return (
            <>
                <ComponentTitleWrapperStyled isSummaryHeader={true} isSummaryFooter={true}>
                    <TableTitleContainerStyled
                        isSubHeader={true}
                        isSummaryHeader={false}
                        isSummaryFooter={false}
                        footerHeight={footerHeight}
                    >
                        <div className={'col d-flex align-items-center'}>
                            <TableTitleStyled isSubHeader={true} footerHeight={footerHeight} isMobile={isMobile}>
                                {tableState.state.subLabel || ''}
                            </TableTitleStyled>
                        </div>
                    </TableTitleContainerStyled>
                </ComponentTitleWrapperStyled>
            </>
        )
    }

    const renderGroupActions = (tableState: TableState): React.ReactElement => {
        if (!tableState?.state.haveGroupActions) {
            return <></>
        }

        if (!tableState?.groupActionState?.haveSelectedRows) {
            return <GroupedActionsBlankRowStyled>&nbsp;</GroupedActionsBlankRowStyled>
        }

        const onActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, actionItem: ActionConfigDTO) => {
            if (e) {
                e.stopPropagation()
            }
            if (!actionItem) {
                return
            }

            const onRefresh = () => {
                refreshData(tableState.queryParamsState.queryParams)
            }

            if (actionItem.actionType === ActionType.ENTRY) {
                dispatch(
                    setActiveAction({
                        action: actionItem,
                        selectedRows: tableState?.groupActionState?.selectedRows,
                        refreshTable: onRefresh,
                    })
                )
            } else {
                dispatch(
                    sendGroupActionRequest({
                        code: code,
                        actionItem: actionItem,
                        sessionContext: sessionContext,
                        abortController: new AbortController(),
                    })
                )
                    .unwrap()
                    .then((reply: GroupActionResponseWrapper) => {
                        dispatch(clearAllRows({ code }))

                        const msg = reply.response?.notificationMessage || 'Action completed'
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: msg,
                            autoCloseDuration: 2000,
                        })
                        refreshData(tableState.queryParamsState.queryParams)
                    })
                    .catch((error: any) => {
                        console.log('Error', error)
                    })
            }
        }

        const actionMediaTypeSensitive = areActionsMediaTypeSensitive(tableState.state.groupActions)
        return (
            <>
                <GroupedActionsMenuBarStyled key={'grouped-actions_' + refreshCounter}>
                    {tableState.state.groupActions
                        // .filter(action => maintainTableService.passesCondition(action, row, sessionContext.getLoggedInUser()))
                        .filter((action) => matchMediaTypeWithAction(action, mediaType, actionMediaTypeSensitive))
                        .map((action: ActionConfigDTO, idx) => (
                            <GroupedActionsWrapperStyled
                                key={'ga_' + idx + '_' + refreshCounter}
                                className={'mx-1'}
                                onClick={(e) => onActionClick(e, action)}
                                active={tableState?.groupActionState?.haveSelectedRows || false}
                            >
                                <Icon className={'action_icon ' + action.icon} icon={action.icon} />
                                <GroupedActionsTextStyled>{action.popUpTitle}</GroupedActionsTextStyled>
                            </GroupedActionsWrapperStyled>
                        ))}
                </GroupedActionsMenuBarStyled>
            </>
        )
    }

    const renderFilterActionItem = (tableState: TableState, filterAction: FilterActionDO, idx: number): React.ReactElement => {
        const onFilterActionItemClick = (filterAction: FilterActionDO) => {
            if (filterAction.route) {
                sessionContext.setQueryParams(JSON.stringify(filterAction.queryParams))
                navigate('/' + filterAction.route)
                return
            }
            setActiveFilterAction(filterAction)
            if (filterAction?.queryParams?.rangeQueries && filterAction.queryParams.rangeQueries[0]) {
                if (filterAction.displayOrder === 0) {
                    // future
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now/d'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now+60d/d'
                }
                if (filterAction.displayOrder === 1) {
                    // today
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now/d'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now+1d/d'
                }
                if (filterAction.displayOrder === 2) {
                    // yesterday
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now-1d/d'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now-1d/d'
                }
                if (filterAction.displayOrder === 3) {
                    // this week
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now-1w/w'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now/w'
                }
                if (filterAction.displayOrder === 4) {
                    // last week
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now-2w/w'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now-1w/w'
                }
                if (filterAction.displayOrder === 5) {
                    // this month
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now/M'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now+1M/M'
                }
                if (filterAction.displayOrder === 6) {
                    filterAction.queryParams.rangeQueries[0].exclusive = true
                    // last month
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now-1M/M'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now/M-1d'
                }
                if (filterAction.displayOrder === 7) {
                    // year
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].from = 'now/y'
                    // @ts-ignore
                    filterAction.queryParams.rangeQueries[0].to = 'now+1y/y'
                }
            }

            // if (tableState?.queryParamsState?.queryParams?.limit) {
            //     filterAction.queryParams.limit =  tableState?.queryParamsState?.queryParams?.limit || 20
            //     filterAction.queryParams.skip = 0; // gbw should be an option
            // }
            dispatch(
                updateQueryParams({
                    code,
                    queryParams: {
                        ...filterAction.queryParams,
                        skip: 0,
                        limit: tableState?.queryParamsState?.queryParams?.limit || 20,
                    },
                })
            )
            setDoneFirstRun(false)
            // refreshData(filterAction.queryParams)
        }

        // MK 06-01-2024
        const containsRangeQueries: boolean = !!(
            filterAction?.queryParams?.rangeQueries && filterAction?.queryParams?.rangeQueries?.length > 0
        )

        const isActive: boolean = containsRangeQueries
            ? filterAction.displayOrder === activeFilterAction?.displayOrder &&
              queryParamsAreTheSame(
                  activeFilterAction?.queryParams as QueryParamsDTO,
                  serverQueryParams || paginationService.blankQueryParam()
              )
            : queryParamsAreTheSame(filterAction?.queryParams as QueryParamsDTO, serverQueryParams || paginationService.blankQueryParam())

        // console.log({
        //     activeFilterAction,
        //     filter: filterAction?.queryParams,
        //     serverQueryParams,
        //     isActive,
        //     control: filterActionControl?.filterActions,
        //     currentQuery
        // })

        return (
            <FilterActionItemStyled
                key={'lvai_' + idx}
                isFlex={false}
                //  active={filterAction.displayOrder === activeFilterAction?.displayOrder}
                active={isActive}
                onClick={() => onFilterActionItemClick(filterAction)}
            >
                {filterAction.label}
            </FilterActionItemStyled>
        )
    }

    const renderFilterAction = (tableState: TableState): React.ReactElement => {
        if (!filterActionControl) {
            return <></>
        }

        return (
            <FilterActionWrapperStyled isFlex={false}>
                {filterActionControl?.filterActions.map((action, idx) => renderFilterActionItem(tableState, action, idx))}
                {displayFavQueriesWithHeaderFilters && favouriteQueries.length ? (
                    favouriteQueries.map((favQuery, idx) => <FavQueryAsHeaderFilter key={'fav-f-' + idx} code={code} favQuery={favQuery} />)
                ) : (
                    <></>
                )}
            </FilterActionWrapperStyled>
        )
    }

    const renderLoading = (tableState: TableState, position: 'top' | 'bottom'): React.ReactElement => {
        if (
            (tableState?.state?.hasInfinityScroll && position === 'top') ||
            (!tableState?.state?.hasInfinityScroll && position === 'bottom')
        ) {
            return <></>
        }
        if (!firstLoadDone || newLoading) {
            return (
                <TableSpinnerRowWrapperStyled>
                    <TableSpinnerColumnWrapperStyled colSpan={colSpan}>
                        <TableSpinner />
                    </TableSpinnerColumnWrapperStyled>
                </TableSpinnerRowWrapperStyled>
            )
        } else {
            return <></>
        }
    }

    const renderNoRowsMessage = (tableState: TableState): React.ReactElement => {
        const dataForTable = isSummaryHeader || isSummaryFooter ? tableData : tableState.state.tableData

        // if (!firstLoadDone || newLoading) {
        //     return (
        //         <TableSpinnerRowWrapperStyled>
        //             <TableSpinnerColumnWrapperStyled colSpan={colSpan}>
        //                 <TableSpinner />
        //             </TableSpinnerColumnWrapperStyled>
        //         </TableSpinnerRowWrapperStyled>
        //     )
        // } else
        if (dataForTable?.length === 0) {
            return (
                <NoRowMessagesStyled>
                    <td colSpan={3}>{noRowsMessage ? noRowsMessage : 'No matching records'}</td>
                </NoRowMessagesStyled>
            )
        } else {
            return <></>
        }
    }

    const renderTheActualTable = (tableState: TableState): React.ReactElement => {
        const onRefresh = () => {
            refreshData(tableState.queryParamsState.queryParams)
        }

        const dataForTable = isSummaryHeader || isSummaryFooter ? tableData : tableState.state.tableData

        const [showPagination, showColumnSettings, showFilter, showRefresh, showResize, showSearch, infinityScroll] = getTableControls(
            environmentContext.getCurrentMediaType(),
            tableState.state.tableControl
        )

        //  const haveTableControls = !tableState.state.disableTableControls || showPagination || showFilter || showSearch || showColumnSettings || (tableState.state.orderByActions || []).length > 0

        const mediaTypeSensitive = areFieldsMediaTypeSensitive(tableState.state.allFields)

        const haveTableHeader =
            (!isMobile && tableState.state.haveRowActions) ||
            tableState.state.allFields
                .filter((fld) => fld.displayInTable)
                .filter((fld) => !fld.noLabel)
                .filter((fld) => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                .map((fld) => fld).length > 0

        // MK 04-01-2024 setting width of actions cell only for visible actions
        const actionMediaTypeSensitive = areActionsMediaTypeSensitive(tableState.state?.rowActions || [])
        let numOfActionsArray: number[] = []

        if (dataForTable?.length) {
            dataForTable.forEach((row) => {
                const numOfActions: number =
                    tableState.state?.rowActions &&
                    tableState.state?.rowActions
                        .filter((action) => maintainTableService.passesCondition(action, row, sessionContext.getLoggedInUser()))
                        .filter((action) => matchMediaTypeWithAction(action, mediaType, actionMediaTypeSensitive)).length
                numOfActionsArray.push(numOfActions)
            })
        }
        const numOfActions: number = numOfActionsArray.length > 0 ? Math.max(...numOfActionsArray) : 0

        // if (_state?.code && _state?.code.indexOf('_') === -1) {
        //     console.log(`code: ${_state?.code} footerHeight: ${footerHeight} startPos: ${guslWrapperStartPos} fixedHeight: ${fixedHeight}`)
        // }

        return (
            <>
                <GuslTableWrapperStyled
                    key={code + '-gtws'}
                    ref={guslWrapperElement}
                    isMobile={isMobile}
                    id={guslTableWrapperId}
                    isSummaryFooter={isSummaryHeader}
                    isNested={isCascadingTable}
                    isSummaryHeader={isSummaryHeader}
                    footerHeight={footerHeight}
                    startPos={guslWrapperStartPos}
                >
                    {renderFilterAction(tableState)}
                    {renderGroupActions(tableState)}
                    {/*{!_state?.loading && (*/}
                    <TableResponsiveStyled
                        code={_state?.code}
                        ref={tableWrapperElement}
                        key={'table_wrapper_' + code + '_' + refreshCounter}
                        width={bodyWidth}
                        className={'table-responsive list-view-table'}
                        id={'table_wrapper_' + code}
                        hide={hide}
                        isSummaryFooter={isSummaryHeader}
                        isNested={isCascadingTable}
                        isSummaryHeader={isSummaryHeader}
                        footerHeight={footerHeight}
                        startPos={tableWrapperStartPos}
                        fixedHeight={fixedHeight}
                        storagePrefix={environmentContext.getStoragePrefix()}
                    >
                        <TableStyled
                            className={'table table-striped'}
                            id={'table_' + code}
                            width={bodyWidth - 10}
                            key={'table_' + code}
                            ref={tableElement}
                        >
                            {haveTableHeader && (
                                <TableHeadStyled
                                    overflown={isOverFlownY || isMobile}
                                    isOverFlownY={isOverFlownY}
                                    isSquashed={isSquashed}
                                    isNested={isNested}
                                    width={bodyWidth}
                                >
                                    <TableHeaderStyled key={'table_hdr_' + code} isMobile={isMobile}>
                                        {/*MK 04-01-2024 -> because tableState?.groupActionState is always undefined*/}
                                        {!isMobile && tableState.state?.haveGroupActions && (
                                            // {!isMobile && tableState?.groupActionState?.haveGroupActions &&

                                            <ColumnHeaderStyled key={'hdr_grp action'} cellWidth={groupActionsCellWidth}>
                                                Select
                                            </ColumnHeaderStyled>
                                        )}
                                        {tableState?.state?.expandableRow && (
                                            <ColumnHeaderStyled key={'hdr_expand'} cellWidth={expandableIconCellWidth} />
                                        )}

                                        {!isMobile && tableState.state.haveRowActions && (
                                            <ActionHeaderColumnStyled key={'hdr_actions'} numberOfActions={numOfActions}>
                                                Actions
                                            </ActionHeaderColumnStyled>
                                        )}

                                        {/* MK 18/09/2023 tableState.state.allFields does not have advancedFilter field, todo investigate why
                                            using fields
                                            */}
                                        {tableState.state.allFields
                                            .filter((fld) => fld.displayInTable)
                                            .filter((fld) => matchMediaTypeWithField(fld, mediaType, mediaTypeSensitive))
                                            .sort((a, b) => compare(a.displayOrder, b.displayOrder))
                                            .map((fld, idx) => (
                                                <TableColumnHeader
                                                    code={code}
                                                    fld={fld}
                                                    topScrollBarPositionRef={topScrollBarPositionRef}
                                                    key={idx}
                                                    disableColumnResize={!showRefresh || disableColumnResize}
                                                />
                                            ))}
                                    </TableHeaderStyled>
                                </TableHeadStyled>
                            )}

                            <TableBodyStyled
                                tBodyHeight={bodyHeight}
                                bodyStartPos={bodyStartPos}
                                isSquashed={isSquashed}
                                isNested={isNested}
                                width={bodyWidth}
                                isBeingResized={typeof currentlyResizingHeaderName !== 'undefined'}
                                isOverFlown={isOverFlownX}
                                id={tableBodyId}
                                className={tableBodyId + '  '}
                                key={tableBodyId + '_' + refreshCounter}
                                isMobile={isMobile}
                                ref={tableBodyElement}
                            >
                                {renderLoading(tableState, 'top')}
                                {renderNoRowsMessage(tableState)}
                                {dataForTable?.map((row: any, rowIndex: number) => (
                                    <TableRow
                                        ref={lastTableRowElement}
                                        firstRow={rowIndex === 0}
                                        lastRow={rowIndex === (dataForTable?.length || 1) - 1}
                                        //MK 05-01-2024
                                        numOfActions={numOfActions}
                                        code={code}
                                        // MK 06/09/2023
                                        isSquashed={isSquashed}
                                        isNested={isNested}
                                        rowIndex={rowIndex}
                                        key={'id_' + rowIndex + '_' + row.id}
                                        rowId={'id_' + (tableState.state.code || 'xx') + '_' + rowIndex + '_' + row.id}
                                        row={row}
                                        fields={tableState.state.allFields
                                            .filter((field) => field.displayInTable)
                                            .filter((field) => matchMediaTypeWithField(field, mediaType, mediaTypeSensitive))
                                            .sort((a, b) => compare(a?.displayOrder, b?.displayOrder))}
                                        menuItem={undefined}
                                        conditionalRowDetails={row.conditionalRowDetails}
                                        performTableRefresh={onRefresh}
                                        firstTab={firstTab}
                                        isSummaryFooter={isSummaryFooter}
                                        isSummaryHeader={isSummaryHeader}
                                        mediaType={mediaType}
                                        widgetPanelProperties={widgetPanelProperties}
                                    />
                                ))}
                                {/*<tr id={"last-row-" + code} data-skip={queryParamState?.queryParams.skip}*/}
                                {/*    data-limit={queryParamState?.queryParams.limit}>*/}
                                {/*    <td></td>*/}
                                {/*</tr>*/}
                                {renderLoading(tableState, 'bottom')}
                            </TableBodyStyled>
                        </TableStyled>
                    </TableResponsiveStyled>
                    {/*)}*/}
                </GuslTableWrapperStyled>
            </>
        )
    }

    const renderTable = (queryParamsState: GuslTableQueryParamState): React.ReactElement => {
        const tableState: TableState = {
            state: _state,
            queryParamsState: queryParamsState,
            groupActionState: _groupActionState,
        }
        const isFirstTable: boolean =
            (!(isSummaryHeader || false) && !(isSummaryFooter || false)) ||
            (notDefined(tableState.state.headerSummary) && !(isSummaryHeader || false))

        return (
            <TableContainerStyled
                key={tableContentId + '_' + tableState.state.refreshCounter + '_' + refreshCounter}
                footerHeight={footerHeight}
                isLoading={!firstLoadDone}
                id={tableContentId + '_' + tableState.state.refreshCounter}
            >
                <ListTableContentStyled
                    key={'tbl_content_' + code + '_' + tableState.state.refreshCounter}
                    ref={contentElement}
                    isMobile={isMobile}
                    isFirstTable={isSummaryHeader}
                    isTabChild={isTabChild}
                    isNested={isCascadingTable}
                    tableStartPos={tableStartPos}
                    footerHeight={footerHeight}
                    storagePrefix={environmentContext.getStoragePrefix()}
                    id={'tbl_content_' + code + '_' + tableState.state.refreshCounter}
                >
                    {renderSummary(tableState.state.code + '_header', tableState, true)}
                    {renderCreateNewEntityDialog(tableState)}
                    {renderInlinePanel(tableState)}
                    {/*{_state?.loading && <TableSpinner/>}*/}
                    {renderTableLabel(tableState)}
                    {isCascadingTable && renderActionBar(tableState)}
                    {renderTheActualTable(tableState)}
                    {renderSummary(tableState.state.code + '_footer', tableState, false)}
                </ListTableContentStyled>
            </TableContainerStyled>
        )
    }

    const renderPage = (queryParamsState: GuslTableQueryParamState): React.ReactElement => {
        if (_state) {
            return (
                <div>
                    {renderTable(queryParamsState)}
                    {/*{firstLoadDone && _state?.loading &&*/}
                    {/*    <TableSpinnerWrapperStyled><TableSpinner/></TableSpinnerWrapperStyled>}*/}
                </div>
            )
        } else {
            return (
                <>
                    {!firstLoadDone && (
                        <TableSpinnerWrapperStyled>
                            <TableSpinner />
                        </TableSpinnerWrapperStyled>
                    )}
                </>
            )
        }
    }

    if (errorMessage) {
        return (
            <>
                <ElementWithModal
                    open={true}
                    element={<span />}
                    modalContent={<p className={'text-center'}>Sorry, system currently not available... </p>}
                />
            </>
        )
    } else {
        return <>{systemReady && _state && renderPage(_queryParamsState)}</>
    }
}
